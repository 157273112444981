import { navigate } from '@gatsbyjs/reach-router';
import { TableCell, TableRow, Tooltip, Typography, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';

import { SearchItem } from '../../types/RealtaDocument';
import { generateDocumentUrl } from '../../utils/document-url';
import Highlighter from './Highlighter';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SearchRow: React.FC<{ item: SearchItem; keyword: string }> = ({ item, keyword }) => {
  const buttonRef = useRef<any>(null);

  useEffect(() => {
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const url = generateDocumentUrl({
        documentId: item.document.uuid,
        sectionId: item.section.uuid,
        page: item.section.startBoundryPage || 1,
      });
      if ((event.ctrlKey && event.button === 0) || (event.metaKey && event.button === 0)) {
        const newTab = window.open('', '_blank');
        newTab!.location = url;
      } else {
        navigate(url);
      }
    };

    const buttonElement = buttonRef.current;
    if (buttonElement) {
      buttonElement.addEventListener('mousedown', (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        handleMouseDown(e)
      );
    }

    return () => {
      if (buttonElement) {
        buttonElement.removeEventListener('mousedown', (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          handleMouseDown(e)
        );
      }
    };
  }, []);

  return (
    <StyledTableRow ref={buttonRef} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'gray' } }}>
      <StyledTableCell component="th" scope="row">
        <b>{item.document.displayName}</b>
      </StyledTableCell>
      <StyledTableCell>
        <Highlighter text={item.section.content || ''} keyword={keyword} />
      </StyledTableCell>
      <StyledTableCell>
        <Tooltip title={`Score: ${Math.round(parseFloat(item.score) * 10000) / 100}`}>
          <Typography>{item.sourcePath}</Typography>
        </Tooltip>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default SearchRow;
